import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Fab, FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import BlockTariffBar from 'components/block/BlockTariffBar';
import BlockTariffItem from 'components/block/BlockTariffItem';
import InputContainer from 'components/form/fields/InputContainer';
import { TARIFF_CYCLE_OPTIONS } from 'utils/constants';
import { TariffCycle, TariffFormRate } from 'utils/types';

const LAST_BLOCK_MIN_SIZE = 2000;

const StyledStepContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BlockCycleContainer = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type BlockBasedTariffProps = {
  cycle: TariffCycle;
  error?: string;
  estimatedAnnualTotalKwh: number;
  isExport?: boolean;
  onChange: (updatedRates: TariffFormRate[]) => void;
  onCycleChange: (cycle: TariffCycle) => void;
  values: TariffFormRate[];
};

type BlockCycleProps = {
  cycle: TariffCycle;
  onCycleChange: (cycle: TariffCycle) => void;
};

const BlockCycle: React.FC<BlockCycleProps> = ({ cycle, onCycleChange }) => {
  const handleCycleChange = (event: SelectChangeEvent) => onCycleChange(event.target.value as TariffCycle);
  return (
    <BlockCycleContainer>
      <Select disableUnderline={true} value={cycle} onChange={handleCycleChange}>
        {TARIFF_CYCLE_OPTIONS.map(t => (
          <MenuItem key={t} value={t}>
            {t.toLocaleUpperCase()}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        This is when your block tariff resets. Block Tariffs are typically based on your bill cycle or anniverysary (yearly)
      </FormHelperText>
    </BlockCycleContainer>
  );
};

const getEstimatedOnCycle = (totalYear: number, cycle: TariffCycle) => {
  switch (cycle) {
    case 'daily':
      return totalYear / 365;
    case 'weekly':
      return totalYear / 52;
    case 'monthly':
      return totalYear / 12;
    case 'bi-monthly':
      return totalYear / 6;
    case 'quarterly':
      return totalYear / 4;
    case 'yearly':
      return totalYear;
  }
};

const BlockBasedTariff: React.FC<BlockBasedTariffProps> = ({
  cycle,
  error,
  estimatedAnnualTotalKwh,
  isExport,
  onChange,
  onCycleChange,
  values,
}) => {
  const estimatedTotal = getEstimatedOnCycle(estimatedAnnualTotalKwh, cycle);
  const onAddMore = () => {
    const [secondLast, last] = values.slice(-2);
    onChange([
      ...values.slice(0, -1),
      {
        ...last,
        threshold: Number(secondLast.threshold) + LAST_BLOCK_MIN_SIZE + '',
      },
      last,
    ]);
  };
  return (
    <InputContainer
      title="Block Tariff"
      description={`Based on your provided data, your ${cycle} estimated ${isExport ? 'exported' : 'consumed'} energy is ${Math.round(estimatedTotal)} kWh`}
    >
      <BlockCycle {...{ cycle, onCycleChange }} />
      <BlockTariffBar rates={values} referenceLine={estimatedTotal} />
      {values.map((value, index, allValues) => {
        const onDelete = () => {
          const newValues = values.filter((_, i) => i !== index);
          newValues[newValues.length - 1].threshold = '';
          onChange(newValues);
        };
        const onBlockChange = (newValue: TariffFormRate) => {
          const newValues = [...values];
          newValues[index] = newValue;
          onChange(newValues);
        };
        const isLast = index === values.length - 1;
        const previousThreshold = index > 0 ? allValues[index - 1].threshold : '0';
        return (
          <StyledStepContainer key={index}>
            <BlockTariffItem
              previousThreshold={previousThreshold}
              value={value}
              onChange={onBlockChange}
              isLast={isLast}
              error={error}
            />
            {index > 1 && (
              <Fab color="error" aria-label="delete step" size="small" onClick={onDelete}>
                <DeleteIcon />
              </Fab>
            )}
          </StyledStepContainer>
        );
      })}
      <Fab color="primary" aria-label="add more" size="small" onClick={onAddMore} sx={{ marginTop: '1rem' }}>
        <AddIcon />
      </Fab>
    </InputContainer>
  );
};

export default BlockBasedTariff;
