import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import CardContainer from 'components/common/card/CardContainer';
import Decimal from 'decimal.js';
import { useMemo } from 'react';
import {
  Bar,
  BarChart,
  Brush,
  Cell,
  Legend,
  Pie,
  PieChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { getAbsolutePeaksByMonth, getInfoFromMeterData } from 'utils/dataReader';
import { getMonthName } from 'utils/timeHelper';
import { decimalToNumber } from 'utils/transform';
import { IntervalData, MonthlyIntervalPeaks } from 'utils/types';

const MeterDataDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
interface CustomizedLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  index: number;
  color?: string;
  percent: number;
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: CustomizedLabelProps) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.25;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const MeterDataDescription: React.FC<{
  data: IntervalData[];
  intervalPeaks?: MonthlyIntervalPeaks;
  totalData?: IntervalData[];
  title: string;
  solarDataLabel?: string;
  dataLabel?: string;
  totalByDateLabel?: string;
  averageByTimeLabel?: string;
}> = ({
  data,
  intervalPeaks,
  title,
  totalData,
  dataLabel = 'from/to grid',
  solarDataLabel = 'total',
  totalByDateLabel = 'Total by Date',
  averageByTimeLabel = 'Average by Time',
}) => {
  const theme = useTheme();

  if (!data.length) {
    return null;
  }

  const { dateAverage, kwhDay, intervalAverage } = getInfoFromMeterData(data, totalData);
  const percentageData = useMemo(() => {
    const totalFromSolar = intervalAverage.reduce((sum, current) => sum + (current.diff || 0), 0);
    const total = intervalAverage.reduce((sum, current) => sum + (current.total || 0), 0);
    if (total === 0) return undefined;
    const solarPercentage = decimalToNumber(new Decimal(totalFromSolar).dividedBy(total).times(100));
    return [
      {
        name: dataLabel,
        value: 100 - solarPercentage,
        color: theme.palette.primary.light,
      },
      {
        name: solarDataLabel,
        value: solarPercentage,
        color: theme.palette.secondary.light,
      },
    ];
  }, [intervalAverage]);

  const hasSolar = percentageData && percentageData[1].value > 0;
  const monthlyPeaksData = !!intervalPeaks
    ? Object.entries(getAbsolutePeaksByMonth(intervalPeaks)).map(([month, peak]) => ({
        month: getMonthName(month),
        kW: peak.maxKW.toFixed(2),
      }))
    : [];
  const yMin = Math.min(...monthlyPeaksData.map(m => Number(m.kW))) - 1;
  const yMax = Math.max(...monthlyPeaksData.map(m => Number(m.kW))) + 0.5;

  return (
    <MeterDataDescriptionContainer>
      <Typography variant="h5">{title}</Typography>
      {hasSolar && (
        <ResponsiveContainer width="100%" height={140}>
          <PieChart>
            <Pie
              data={percentageData}
              dataKey="value"
              startAngle={180}
              endAngle={0}
              innerRadius={40}
              outerRadius={100}
              cx="50%"
              cy="100%"
              labelLine={false}
              label={renderCustomizedLabel}
            >
              {percentageData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      )}
      <CardContainer title={totalByDateLabel} caption={kwhDay.toFixed(2) + ' kWh/d'}>
        <ResponsiveContainer width="100%" height={224}>
          <BarChart data={dateAverage}>
            <XAxis dataKey="description" minTickGap={25} />
            <Tooltip />
            <Bar
              dataKey="kwh"
              stroke={theme.palette.primary.light}
              fill={theme.palette.primary.light}
              name={dataLabel}
              unit="kWh"
              stackId="a"
              radius={hasSolar ? 0 : [5, 5, 0, 0]}
            />
            <Bar
              dataKey="diff"
              stroke={theme.palette.secondary.light}
              fill={theme.palette.secondary.light}
              name={solarDataLabel}
              unit="kWh"
              stackId="a"
              radius={[5, 5, 0, 0]}
            />
            <ReferenceLine y={kwhDay.toFixed(2)} stroke={theme.palette.primary.main} strokeDasharray="10 20"></ReferenceLine>
            <Brush dataKey="description" startIndex={dateAverage.length - 30} height={25} stroke={theme.palette.primary.light} />
          </BarChart>
        </ResponsiveContainer>
      </CardContainer>
      <CardContainer title={averageByTimeLabel} caption={(kwhDay / intervalAverage.length).toFixed(2) + ' kWh'}>
        <ResponsiveContainer width="100%" height={224}>
          <BarChart data={intervalAverage}>
            <XAxis dataKey="description" minTickGap={25} />
            <Tooltip />
            <Bar
              dataKey="kwh"
              fill={theme.palette.primary.light}
              stroke={theme.palette.primary.light}
              name={dataLabel}
              unit="kWh"
              stackId="a"
              radius={hasSolar ? 0 : [5, 5, 0, 0]}
            >
              {intervalAverage.map(entry => (
                <Cell
                  cursor="pointer"
                  fill={theme.palette.primary.light}
                  stroke={theme.palette.primary.light}
                  key={`cell-${entry.description}`}
                />
              ))}
            </Bar>
            <Bar
              dataKey="diff"
              stroke={theme.palette.secondary.light}
              fill={theme.palette.secondary.light}
              name={solarDataLabel}
              unit="kWh"
              stackId="a"
              radius={[5, 5, 0, 0]}
            />
            <ReferenceLine
              y={(kwhDay / intervalAverage.length).toFixed(2)}
              stroke={theme.palette.primary.main}
              strokeDasharray="10 20"
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContainer>
      {!!monthlyPeaksData.length && (
        <CardContainer title="Monthly Max Demands">
          <ResponsiveContainer width="100%" height={148}>
            <BarChart data={monthlyPeaksData}>
              <XAxis dataKey="month" />
              <YAxis domain={[yMin, yMax]} hide={true} />
              <Tooltip />
              <Bar dataKey="kW" fill={theme.palette.primary.light} />
            </BarChart>
          </ResponsiveContainer>
        </CardContainer>
      )}
    </MeterDataDescriptionContainer>
  );
};

export default MeterDataDescription;
