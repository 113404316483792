import { Box, Typography } from '@mui/material';
import TariffRatesView from 'components/tariff/TariffRatesView';
import { Tariff, TariffDiscount, TariffDiscountMethod, TariffDiscountType } from 'utils/types';

const formatAmount = (amount: number) => `${amount.toFixed(2).replace(/\.?0+$/, '')}`;
const formatPercentage = (percentage: number) => `${formatAmount(percentage * 100)}%`;

const stringifyDiscount = (discount: TariffDiscount) => {
  return `${formatPercentage(discount.percentage)} of ${discount.method === TariffDiscountMethod.BILL ? 'bill amount' : 'usage'}`;
};

const TariffDetails: React.FC<{ tariff: Tariff }> = ({ tariff }) => {
  const discounts = tariff.discounts ?? [];
  const discountsByType: Record<TariffDiscountType, TariffDiscount[]> = discounts.reduce(
    (acc, current) => ({
      ...acc,
      [current.type]: [...acc[current.type], current],
    }),
    {
      guaranteed: [],
      conditional: [],
      other: [],
    },
  );
  return (
    <>
      <Typography variant="h2" fontWeight="bold">
        {tariff.retailer}
      </Typography>
      <Typography variant="h5">{tariff.name}</Typography>
      <Typography variant="subtitle2">{tariff.id}</Typography>
      {!!tariff.eligibility.length && (
        <Typography variant="h5" fontWeight="bold" sx={{ mt: 2 }}>
          Eligibility
        </Typography>
      )}
      {tariff.eligibility.map((e, i) => (
        <Typography key={i} variant="body1" style={{ whiteSpace: 'pre-line' }}>
          {e}
        </Typography>
      ))}
      <Typography variant="h5" fontWeight="bold" sx={{ mt: 2 }}>
        Offer details
      </Typography>
      <Typography variant="h6" sx={{ mt: 1 }}>
        Supply charges
      </Typography>
      <Typography fontWeight="bold">
        {formatAmount(tariff.supply.rate)} {tariff.supply.type}
      </Typography>
      {!!tariff.consumptionRates.length && (
        <Typography variant="h6" sx={{ mt: 1 }}>
          Usage rates
        </Typography>
      )}
      {!!tariff.consumptionRates.length && <TariffRatesView rates={tariff.consumptionRates} />}
      {!!tariff.demandRates.length && (
        <Typography variant="h6" sx={{ mt: 1 }}>
          Demand rates
        </Typography>
      )}
      {!!tariff.demandRates.length && <TariffRatesView rates={tariff.demandRates} />}
      {/* {!!tariff.controlledLoadRates.length && <Typography variant="h6" sx={{mt: 1}}>Controlled load rates</Typography>} */}
      {/* {!!tariff.controlledLoadRates.length && <TariffRatesView rates={tariff.controlledLoadRates}/>} */}
      {!!tariff.generationRates.length && (
        <Typography variant="h6" sx={{ mt: 1 }}>
          Export rates
        </Typography>
      )}
      {!!tariff.generationRates.length && <TariffRatesView rates={tariff.generationRates} />}
      {!!discounts.length && (
        <Typography variant="h5" fontWeight="bold" sx={{ mt: 2 }}>
          Discounts
        </Typography>
      )}
      {!!discountsByType['guaranteed'].length && (
        <>
          <Typography variant="h6" sx={{ mt: 1 }}>
            Guaranteed Discounts
          </Typography>
          {discountsByType['guaranteed'].map((d, i) => (
            <Typography key={i} variant="body1">
              <b>{stringifyDiscount(d)}</b>. {d.description}
            </Typography>
          ))}
        </>
      )}
      {!!discountsByType['conditional'].length && (
        <>
          <Typography variant="h6" sx={{ mt: 1 }}>
            Conditional Discounts
          </Typography>
          {discountsByType['conditional'].map((d, i) => (
            <Typography key={i} variant="body1">
              <b>{stringifyDiscount(d)}</b>. {d.description}
            </Typography>
          ))}
        </>
      )}
      {!!discountsByType['other'].length && (
        <>
          <Typography variant="h6" sx={{ mt: 1 }}>
            Other Discounts
          </Typography>
          {discountsByType['other'].map((d, i) => (
            <Typography key={i} variant="body1">
              <b>{stringifyDiscount(d)}</b>. {d.description}
            </Typography>
          ))}
        </>
      )}
      {!!tariff.incentives?.length && (
        <Typography variant="h5" fontWeight="bold" sx={{ mt: 2 }}>
          Incentives
        </Typography>
      )}
      {tariff.incentives?.map((inc, i) => (
        <Box sx={{ mt: 1 }} key={`inc-${i}`}>
          <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
            {inc.description}
          </Typography>
          {!!inc.eligibility && (
            <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
              {inc.eligibility}
            </Typography>
          )}
        </Box>
      ))}
      {!!tariff.fees?.length && (
        <Typography variant="h5" fontWeight="bold" sx={{ mt: 2 }}>
          Fees
        </Typography>
      )}
      {tariff.fees?.map((fee, i) => (
        <Box sx={{ mt: 1 }} key={`fee-${i}`}>
          <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
            {fee.description}
          </Typography>
          <Typography variant="body1">
            {!!fee.rate
              ? `Percentage of bil: ${formatPercentage(Number(fee.rate))}`
              : `Flat rate: $${formatAmount(Number(fee.amount))}`}
          </Typography>
        </Box>
      ))}
    </>
  );
};

export default TariffDetails;
