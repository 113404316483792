import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EyeIcon from '@mui/icons-material/Visibility';
import { IconButton, Paper, TablePagination, Tooltip, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ChangeEvent, useMemo, useState } from 'react';
import { Tariff, TariffWithBreakdownAndTotal } from 'utils/types';

type TariffTableProps = {
  onView: (tariff: Tariff) => void;
  onRemove: (tariff: Tariff) => void;
  tariffs: TariffWithBreakdownAndTotal[];
  withConditional?: boolean;
};

const StyledPaper = styled(Paper)`
  box-shadow:
    0px 12px 24px -4px ${({ theme }) => theme.palette.grey[100]},
    0px 0px 2px 0px ${({ theme }) => theme.palette.grey[100]};
  border-radius: 1rem 1rem 0.25rem 0.25rem;
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.secondary,
  },
}));

const ActionTableCell = styled(TableCell)`
  width: fit-content;
`;

const StyledTitle = styled(Typography)`
  margin: 1rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const TariffTable: React.FC<TariffTableProps> = ({ onView, onRemove, tariffs, withConditional = false }) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const handleChangePage = (_e: unknown, p: number) => setPage(p);

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const rows = parseInt(event.target.value, 10);
    setRowsPerPage(rows);
    setPage(0);
  };

  const visibleRows = useMemo(
    () => tariffs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, tariffs],
  );

  return (
    <StyledPaper>
      <StyledTitle variant="h6" fontWeight="bold">
        Retailers found
      </StyledTitle>
      <TableContainer>
        <Table aria-label="tariffs table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: '15%' }}>Retailer</StyledTableCell>
              <StyledTableCell sx={{ width: '20%' }}>Tariff</StyledTableCell>
              <StyledTableCell sx={{ width: '50%' }}>Deal</StyledTableCell>
              <StyledTableCell sx={{ width: '5%' }} />
              <StyledTableCell sx={{ width: '5%' }} />
              <StyledTableCell sx={{ width: '5%' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map(({ tariff, breakdown, total, totalWithConditionalDiscounts }, i) => {
              const hasConditionalDiscounts = total !== totalWithConditionalDiscounts;
              const primaryTotal = withConditional ? totalWithConditionalDiscounts : total;
              const secondaryTotal = withConditional ? total : totalWithConditionalDiscounts;
              return (
                <TableRow key={`${tariff.id}-${i}`}>
                  <TableCell component="th" scope="row">
                    {tariff.retailer}
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        hasConditionalDiscounts
                          ? `${withConditional ? 'without' : 'with'} conditional discounts: $${(Math.round(secondaryTotal / 5) * 5).toFixed(0)}`
                          : ''
                      }
                      arrow
                      disableHoverListener={!hasConditionalDiscounts}
                    >
                      <Typography variant="h6" color="textPrimary">
                        {`$${(Math.round(primaryTotal / 5) * 5).toFixed(0)} / Year`}
                        {hasConditionalDiscounts && ' *'}
                      </Typography>
                    </Tooltip>
                    {!!breakdown.generation && (
                      <Typography variant="body2">{`Solar Credit: $${Math.round(breakdown.generation)}`}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{tariff.name}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {`ID: ${tariff.id}`}
                    </Typography>
                  </TableCell>
                  <ActionTableCell sx={{ paddingRight: 0 }}>
                    {tariff.termType?.includes('year') && (
                      <Tooltip title={tariff.termType} arrow>
                        <IconButton aria-label="view" size="small">
                          <DateRangeIcon fontSize="small" fill="textSecondary" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </ActionTableCell>
                  <ActionTableCell sx={{ paddingRight: 0 }}>
                    <IconButton aria-label="view" size="small" onClick={() => onView(tariff)}>
                      <EyeIcon fontSize="small" fill="textSecondary" />
                    </IconButton>
                  </ActionTableCell>
                  <ActionTableCell>
                    <IconButton aria-label="delete" size="small" onClick={() => onRemove(tariff)}>
                      <CloseIcon fontSize="small" fill="textSecondary" />
                    </IconButton>
                  </ActionTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tariffs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledPaper>
  );
};

export default TariffTable;
