import dayjs, { Dayjs } from 'dayjs';
import Decimal from 'decimal.js';
import { INTERVAL_LENGTH } from 'utils/constants';
import { getDaysInMonth, getFormattedMonth, isMonthInRange } from 'utils/timeHelper';
import { decimalToNumber, getTotal, standariseTariff } from 'utils/transform';
import {
  BlockCycle,
  ControlledPricingBreakdown,
  DatePricing,
  DayOfTheWeekIndexedIntervalPeaks,
  DayOfTheWeekIntervalPeaks,
  FeedData,
  IntervalData,
  IntervalPricing,
  IntervalPricingData,
  MonthDatePricing,
  MonthlyDemandPeaks,
  MonthlyIntervalPeaks,
  PricingBreakdown,
  PricingBreakdownByDate,
  RatesByDayAndInterval,
  RatesByInterval,
  Tariff,
  TariffCycle,
  TariffDiscount,
  TariffDiscountMethod,
  TariffRate,
} from 'utils/types';

type PricingResultByDate = {
  byMonth: {
    [month: string]: MonthDatePricing;
  };
  byDay: DatePricing[];
};

const increaseYear = ({ date, ...rest }: IntervalData): IntervalData => {
  const newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() + 1);
  return { ...rest, date: newDate };
};

// NOTE (pdiego): Reorder data based on the start date of the Tariff.
// This is important for block cycles.
const reorderData = (data: IntervalData[], startIndex: number): IntervalData[] => [
  ...data.slice(startIndex),
  ...data.slice(0, startIndex).map(increaseYear),
];

const applyDiscount = (value: number, discountRate = 0) => value * (1 - discountRate);

export const getRatesByDayAndInterval = (tariffRates: TariffRate[]): RatesByDayAndInterval => {
  const result: RatesByDayAndInterval = {};

  for (const rate of tariffRates) {
    const { period, ...rateEntry } = rate;

    for (const dayOfTheWeek of period.daysOfTheWeekIndex) {
      result[dayOfTheWeek] ??= {};

      for (const intervalIndex of period.intervalsIndex) {
        result[dayOfTheWeek][intervalIndex] ??= [];
        result[dayOfTheWeek][intervalIndex].push(rateEntry);
      }
    }
  }

  return result;
};

export const filterMonthlyIntervalPeaks = (
  intervalPeaks: DayOfTheWeekIntervalPeaks,
  demandRates: TariffRate[],
): DayOfTheWeekIndexedIntervalPeaks => {
  return demandRates.reduce((filteredPeaks, { period }) => {
    const daysOfTheWeekIndex = period?.daysOfTheWeekIndex || [];
    const peakIntervalsIndex = period?.intervalsIndex || [];

    return daysOfTheWeekIndex.reduce((acc, dayOfTheWeek) => {
      const filteredIntervals = peakIntervalsIndex.reduce(
        (intervalAcc, intervalIndex) => {
          if (intervalPeaks[dayOfTheWeek]?.[intervalIndex] !== undefined) {
            intervalAcc[intervalIndex] = intervalPeaks[dayOfTheWeek][intervalIndex];
          }
          return intervalAcc;
        },
        {} as { [intervalIndex: number]: number },
      );

      return {
        ...acc,
        [dayOfTheWeek]: {
          ...acc[dayOfTheWeek],
          ...filteredIntervals,
        },
      };
    }, filteredPeaks);
  }, {} as DayOfTheWeekIndexedIntervalPeaks);
};

export const getMaxKW = (dayOfTheWeekIntervalPeaks: DayOfTheWeekIndexedIntervalPeaks) => {
  return Object.values(dayOfTheWeekIntervalPeaks).reduce((maxKW, peaksByInterval) => {
    const allPeaks = Object.values(peaksByInterval);
    const maxKWForDay = Math.max(...allPeaks);
    return Math.max(maxKW, maxKWForDay);
  }, 0);
};

export const getDemandPeaks = (intervalPeaks: MonthlyIntervalPeaks, demandRates: TariffRate[]): MonthlyDemandPeaks => {
  return Object.keys(intervalPeaks).reduce((acc, month) => {
    const { days, maxKWByDayOfTheWeek } = intervalPeaks[month];
    const demandRatesByMonth = demandRates.filter(rt => isMonthInRange(month, rt.startDate, rt.endDate));
    const filteredPeaks = filterMonthlyIntervalPeaks(maxKWByDayOfTheWeek, demandRatesByMonth);
    const maxKW = getMaxKW(filteredPeaks);
    // NOTE (pdiego): Assumption is that the demand rate is the same for the whole month
    const rate = demandRatesByMonth[0]?.rate || 0;
    return {
      ...acc,
      [month]: {
        days,
        maxKW,
        rate,
      },
    };
  }, {} as MonthlyDemandPeaks);
};

const intervalsToPricingBreakdown = (intervals: IntervalPricing[]): PricingBreakdown =>
  intervals.reduce(
    (acc: PricingBreakdown, interval: IntervalPricing) => {
      const { supply, demand, consumption, generation, controlledLoad } = interval.price;
      const intervalControlledLoadSupplySum = controlledLoad.reduce((acc, current) => acc + current.supply, 0);
      const intervalControlledLoadUsageSum = controlledLoad.reduce((acc, current) => acc + current.rate, 0);
      return {
        supply: decimalToNumber(new Decimal(acc.supply).plus(supply)),
        demand: decimalToNumber(new Decimal(acc.demand).plus(demand)),
        consumption: decimalToNumber(new Decimal(acc.consumption).plus(consumption)),
        controlledLoad: controlledLoad.map((acl, i) => ({
          supply: decimalToNumber(new Decimal(acl.supply).plus(acc.controlledLoad?.[i]?.supply || 0)),
          rate: decimalToNumber(new Decimal(acl.rate).plus(acc.controlledLoad?.[i]?.rate || 0)),
        })),
        controlledLoadTotalSupply: decimalToNumber(
          new Decimal(acc.controlledLoadTotalSupply || 0).plus(intervalControlledLoadSupplySum),
        ),
        controlledLoadTotalUsage: decimalToNumber(
          new Decimal(acc.controlledLoadTotalUsage || 0).plus(intervalControlledLoadUsageSum),
        ),
        generation: decimalToNumber(new Decimal(acc.generation).plus(generation)),
        total: new Decimal(acc.total || 0).plus(getTotal(interval.price)).toNumber(),
      };
    },
    {
      consumption: 0,
      controlledLoad: [],
      controlledLoadTotalSupply: 0,
      controlledLoadTotalUsage: 0,
      demand: 0,
      generation: 0,
      supply: 0,
      total: 0,
    } as PricingBreakdown,
  );

const isStartOfCycle = ({ startDate, cycle, currentDate }: { startDate: Dayjs; cycle: TariffCycle; currentDate: Dayjs }) => {
  if (cycle === 'daily') return true;
  if (cycle === 'weekly') {
    const dayDiff = startDate.diff(currentDate, 'day');
    return dayDiff % 7 === 0;
  }
  const monthDiff = startDate.diff(currentDate, 'month', true);
  switch (cycle) {
    case 'monthly':
      return monthDiff % 1 === 0;
    case 'bi-monthly':
      return monthDiff % 2 === 0;
    case 'quarterly':
      return monthDiff % 3 === 0;
    case 'yearly':
      return monthDiff % 12 === 0;
  }
};

const getFirstIntervalOfTheDay = (
  cycle: BlockCycle,
  date: Dayjs,
  startDate: Dayjs,
  previousDatePricing: IntervalPricing[],
): IntervalPricing => {
  const lastIntervalFromPreviousDay = previousDatePricing?.[previousDatePricing.length - 1] ?? {
    accumulatedKwh: {
      consumption: 0,
      generation: 0,
      controlledLoad: [],
    },
    accumulatedPrice: {
      supply: 0,
      consumption: 0,
      generation: 0,
      demand: 0,
      controlledLoad: [],
    },
  };
  const normalizedStartDate = startDate.startOf('day');
  const normalizedCurrentDate = date.startOf('day');
  const isConsumptionCycleStart = isStartOfCycle({
    startDate: normalizedStartDate,
    cycle: cycle.consumption,
    currentDate: normalizedCurrentDate,
  });
  const isGenerationCycleStart =
    cycle.generation === cycle.consumption
      ? isConsumptionCycleStart
      : isStartOfCycle({
          startDate: normalizedStartDate,
          cycle: cycle.generation,
          currentDate: normalizedCurrentDate,
        });
  return {
    ...lastIntervalFromPreviousDay,
    accumulatedKwh: {
      consumption: isConsumptionCycleStart ? 0 : lastIntervalFromPreviousDay.accumulatedKwh.consumption,
      generation: isGenerationCycleStart ? 0 : lastIntervalFromPreviousDay.accumulatedKwh.generation,
      controlledLoad: lastIntervalFromPreviousDay.accumulatedKwh.controlledLoad,
    },
  };
};

const getIntervalRate = (rates: RatesByInterval, intervalIndex: number, accummulatedKwh: number, discount = 0) => {
  const rateThresholds = rates[intervalIndex] || [];
  const rate = rateThresholds.find(rt => !rt.threshold || accummulatedKwh <= rt.threshold)?.rate || 0;
  return applyDiscount(rate, discount);
};

const getGuaranteedDiscounts = (discounts: TariffDiscount[]) => {
  const guaranteedDiscounts = discounts.filter(({ type }) => type === 'guaranteed');
  return {
    usage: guaranteedDiscounts.find(d => d.method === TariffDiscountMethod.CONSUMPTION)?.percentage || 0,
    bill: guaranteedDiscounts.find(d => d.method === TariffDiscountMethod.BILL)?.percentage || 0,
  };
};

const mergePricingBreakdown = (a: PricingBreakdown, b: PricingBreakdown): PricingBreakdown => {
  const supplyCost = a.supply + b.supply;
  const consumptionCost = a.consumption + b.consumption;
  const generationCost = a.generation + b.generation;
  const demandCost = a.demand + b.demand;
  const controlledLoadCost = b.controlledLoad.map((cl, clIndex) => ({
    supply: cl.supply + a.controlledLoad[clIndex].supply,
    rate: cl.rate + a.controlledLoad[clIndex].rate,
  }));

  const controlledLoadTotalSupply = a.controlledLoadTotalSupply + b.controlledLoadTotalSupply;
  const controlledLoadTotalUsage = a.controlledLoadTotalUsage + b.controlledLoadTotalUsage;
  const total = (a.total || 0) + (b.total || 0);

  return {
    supply: supplyCost,
    consumption: consumptionCost,
    controlledLoad: controlledLoadCost,
    controlledLoadTotalSupply,
    controlledLoadTotalUsage,
    generation: generationCost,
    demand: demandCost,
    total,
  };
};

const PricingService = {
  getPricingFromMeterDataAndTariff: (
    consumptionData: IntervalData[],
    generationData: IntervalData[],
    controlledLoadData: FeedData[],
    tariff: Tariff,
    intervalPeaks: MonthlyIntervalPeaks,
  ): IntervalPricingData[] => {
    const {
      supply,
      consumptionRates,
      controlledLoadRates,
      generationRates,
      demandRates,
      startDate,
      cycle,
      discounts = [],
    } = standariseTariff(tariff);
    const { usage: usageDiscount, bill: billDiscount } = getGuaranteedDiscounts(discounts);

    const startIndex = consumptionData.findIndex(data => {
      const dayjsDate = dayjs(data.date);
      return dayjsDate.month() === startDate.month() && dayjsDate.date() === startDate.date();
    });

    // NOTE(pdiego): This is assuming we have a full year of data (nor more or less)
    // Reordering data using the startDate of the tariff is relevant for block cycles.
    const orderedConsumptionData = reorderData(consumptionData, startIndex);
    const orderedGenerationData = reorderData(generationData, startIndex);
    const orderedControlledLoadData = controlledLoadData.map(cl => reorderData(cl, startIndex));

    const monthlyDemandPeaks = demandRates.length ? getDemandPeaks(intervalPeaks, demandRates) : undefined;

    const consumptionRatesByDay = getRatesByDayAndInterval(consumptionRates);
    const generationRatesByDay = getRatesByDayAndInterval(generationRates);

    const result: IntervalPricingData[] = orderedConsumptionData.reduce(
      (acc: IntervalPricingData[], current: IntervalData, i: number) => {
        // DAY LEVEL
        const month = getFormattedMonth(current.date, true);
        const demandPeak = monthlyDemandPeaks?.[month];
        const dayOfTheWeek = current.date.getDay();
        const consumptionRatesByInterval: RatesByInterval = consumptionRatesByDay[dayOfTheWeek] ?? [];
        const generationRatesByInterval: RatesByInterval = generationRatesByDay[dayOfTheWeek] ?? [];
        const previousDatePricing = acc[acc.length - 1]?.intervals;

        const intervalDayPricing = current.intervals.reduce(
          (accumulatedDay: IntervalPricing[], consumptionKwh: number, intervalIndex: number) => {
            // INTERVAL LEVEL (30 minutes interval)
            const previousIntervalPricing: IntervalPricing =
              intervalIndex === 0
                ? getFirstIntervalOfTheDay(cycle, dayjs(current.date), startDate, previousDatePricing)
                : accumulatedDay[accumulatedDay.length - 1];
            const { accumulatedKwh, accumulatedPrice } = previousIntervalPricing;

            // SUPPLY
            const supplyRate = applyDiscount(supply.rate, billDiscount);
            const supplyCost = new Decimal(supplyRate).dividedBy(INTERVAL_LENGTH).toNumber();

            // USAGE
            const consumptionRate = getIntervalRate(
              consumptionRatesByInterval,
              intervalIndex,
              accumulatedKwh.consumption,
              usageDiscount || billDiscount,
            );
            const consumptionCost = consumptionKwh * consumptionRate;

            // DEMAND
            let demandCost = 0;
            let demandRate = 0;
            if (demandPeak) {
              demandRate = applyDiscount(demandPeak.rate, billDiscount);
              demandCost = new Decimal(demandRate).times(demandPeak.maxKW).dividedBy(INTERVAL_LENGTH).toNumber();
            }

            // GENERATION
            let generationGain = 0;
            let generationKwh = 0;
            let generationRate = 0;
            if (generationData.length) {
              generationKwh = orderedGenerationData[i].intervals[intervalIndex];
            }
            if (generationRates.length) {
              generationRate = getIntervalRate(generationRatesByInterval, intervalIndex, accumulatedKwh.generation);
              generationGain = generationKwh * generationRate;
            }

            // CONTROLLED LOAD
            let controlledLoadCosts: ControlledPricingBreakdown[] = [];
            let discountedControlledLoadRates: ControlledPricingBreakdown[] = [];
            let controlledLoadKwhs: number[] = [];

            if (controlledLoadData.length && controlledLoadRates.length) {
              // NOTE(pdiego): Controlled Load Rates are flat rate.
              discountedControlledLoadRates = controlledLoadRates.map(cr => ({
                supply: applyDiscount(cr.supply.rate, billDiscount),
                rate: applyDiscount(cr.rate.rate, usageDiscount || billDiscount),
              }));
              controlledLoadKwhs = orderedControlledLoadData.map(cl => cl[i].intervals[intervalIndex]);
              controlledLoadCosts = discountedControlledLoadRates.map((cl, clIndex) => ({
                supply: new Decimal(cl.supply).dividedBy(INTERVAL_LENGTH).toNumber(),
                rate: cl.rate * controlledLoadKwhs[clIndex],
              }));
            }

            const controlledLoad = controlledLoadCosts.map((cl, clIndex) => ({
              supply: cl.supply + (accumulatedPrice.controlledLoad[clIndex]?.supply || 0),
              rate: cl.rate + (accumulatedPrice.controlledLoad[clIndex]?.rate || 0),
            }));

            const controlledLoadTotalSupply = controlledLoad.reduce((acc, current) => acc + current.supply, 0);

            const controlledLoadTotalUsage = controlledLoad.reduce((acc, current) => acc + current.rate, 0);

            return [
              ...accumulatedDay,
              {
                accumulatedKwh: {
                  consumption: accumulatedKwh.consumption + consumptionKwh,
                  generation: accumulatedKwh.generation + generationKwh,
                  controlledLoad: controlledLoadKwhs.map((clKwh, clIndex) => clKwh + accumulatedKwh.controlledLoad[clIndex] || 0),
                },
                accumulatedPrice: {
                  supply: accumulatedPrice.supply + supplyCost,
                  consumption: accumulatedPrice.consumption + consumptionCost,
                  generation: accumulatedPrice.generation + generationGain,
                  demand: accumulatedPrice.demand + demandCost,
                  controlledLoad,
                  controlledLoadTotalSupply,
                  controlledLoadTotalUsage,
                },
                price: {
                  supply: supplyCost,
                  consumption: consumptionCost,
                  generation: generationGain,
                  demand: demandCost,
                  controlledLoad: controlledLoadCosts,
                  controlledLoadTotalSupply: controlledLoadCosts.reduce((acc, current) => acc + current.supply, 0),
                  controlledLoadTotalUsage: controlledLoadCosts.reduce((acc, current) => acc + current.rate, 0),
                },
                rates: {
                  supply: supplyRate,
                  consumption: consumptionRate,
                  generation: generationRate,
                  demand: demandRate,
                  controlledLoad: discountedControlledLoadRates,
                  controlledLoadTotalSupply: discountedControlledLoadRates.reduce((acc, current) => acc + current.supply, 0),
                  controlledLoadTotalUsage: discountedControlledLoadRates.reduce((acc, current) => acc + current.rate, 0),
                },
              },
            ];
          },
          [] as IntervalPricing[],
        );
        acc.push({ date: current.date, intervals: intervalDayPricing });
        return acc;
      },
      [] as IntervalPricingData[],
    );
    return result;
  },

  /**
   * @param pricingData
   * @returns Broken down pricing data by month and by day in order to display it in the UI
   * This is used for the tariff summary page
   */
  getPricingBreakdownByDate: (pricingData: IntervalPricingData[]): PricingBreakdownByDate => {
    const resultByDate: PricingResultByDate = pricingData.reduce(
      ({ byMonth, byDay }: PricingResultByDate, entry: IntervalPricingData) => {
        const { date } = entry;
        const day = date.getDate();
        const monthKey = getFormattedMonth(date);
        const currentMonth: MonthDatePricing = byMonth[monthKey] ?? {
          date: monthKey,
          totalDaysData: 0,
          supply: 0,
          consumption: 0,
          controlledLoad: [],
          controlledLoadTotalSupply: 0,
          controlledLoadTotalUsage: 0,
          generation: 0,
          demand: 0,
          total: 0,
        };
        const dayBreakdown = intervalsToPricingBreakdown(entry.intervals);

        const dayPricingEntry = {
          date: `${monthKey}-${day < 10 ? '0' : ''}${day}`,
          ...dayBreakdown,
        };
        const totalBreakdown = mergePricingBreakdown(currentMonth, dayBreakdown);
        return {
          byMonth: {
            ...byMonth,
            [monthKey]: {
              date: monthKey,
              totalDaysData: currentMonth.totalDaysData + 1,
              ...totalBreakdown,
            },
          },
          byDay: [...byDay, dayPricingEntry],
        };
      },
      {
        byMonth: {},
        byDay: [],
      },
    );
    return {
      // NOTE (pdiego): Some months could not have a month worth of data, so we need to estimate
      // the cost for the whole month based on the data we have.
      byMonth: Object.entries(resultByDate.byMonth).map(([key, value]) => {
        const daysInMonth = getDaysInMonth(Number(key.split('-')[1]), Number(key.split('-')[0]));
        const totalDaysData = value.totalDaysData;
        const monthPortion = new Decimal(daysInMonth).dividedBy(totalDaysData);
        const monthEstimate = (value: number) => decimalToNumber(new Decimal(value).times(monthPortion));
        return {
          date: key,
          supply: monthEstimate(value.supply),
          consumption: monthEstimate(value.consumption),
          generation: monthEstimate(value.generation),
          controlledLoad: value.controlledLoad.map(({ supply, rate }) => ({
            supply: monthEstimate(supply),
            rate: monthEstimate(rate),
          })),
          controlledLoadTotalSupply: monthEstimate(value.controlledLoadTotalSupply),
          controlledLoadTotalUsage: monthEstimate(value.controlledLoadTotalUsage),
          demand: monthEstimate(value.demand),
          total: value.total ? monthEstimate(value.total) : 0,
        };
      }),
      byDay: resultByDate.byDay,
    };
  },
};

export default PricingService;
