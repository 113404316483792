import { AERPlanFeeType } from 'services/aer/types';
import { useStore } from 'store';
import { getTotal } from 'utils/transform';
import { PricingBreakdown, Tariff, TariffDiscountMethod, TariffWithBreakdownAndTotal } from 'utils/types';

const applyDiscount = (value: number, discountRate = 0) => value * (1 - discountRate);

const useTariffsWithPricingBreakdown = (
  tariffs: Tariff[],
  includeConditionalDiscounts = false,
  ordered = true,
): TariffWithBreakdownAndTotal[] => {
  const { tariffCostsById } = useStore();

  const toTariffWithPricingBreakdown = (tariff: Tariff) => {
    const breakdown = tariffCostsById[tariff.id];

    let total = getTotal(breakdown);
    const membershipFee = tariff.fees?.find(fee => fee.type === AERPlanFeeType.MEMBERSHIP);
    if (membershipFee) {
      total += Number(membershipFee.amount ?? 0);
      total += total * Number(membershipFee.rate ?? 0);
    }
    let totalWithConditionalDiscounts: number = total;
    const conditionalDiscounts = (tariff.discounts ?? []).filter(({ type }) => type === 'conditional');
    if (conditionalDiscounts.length > 0) {
      const usage = conditionalDiscounts.find(d => d.method === TariffDiscountMethod.CONSUMPTION)?.percentage ?? 0;
      const bill = conditionalDiscounts.find(d => d.method === TariffDiscountMethod.BILL)?.percentage ?? 0;

      const breakdownWithConditionalDiscounts: PricingBreakdown = {
        ...breakdown,
        supply: applyDiscount(breakdown.supply, bill),
        consumption: applyDiscount(breakdown.consumption, usage || bill),
        controlledLoadTotalSupply: applyDiscount(breakdown.controlledLoadTotalSupply, bill),
        controlledLoadTotalUsage: applyDiscount(breakdown.controlledLoadTotalUsage, usage || bill),
        demand: applyDiscount(breakdown.demand, bill),
      };

      totalWithConditionalDiscounts = getTotal(breakdownWithConditionalDiscounts);
    }
    return {
      tariff,
      breakdown,
      total,
      totalWithConditionalDiscounts,
    };
  };

  const tariffsWithBreakdown = tariffs.map(toTariffWithPricingBreakdown);
  if (ordered) {
    return includeConditionalDiscounts
      ? tariffsWithBreakdown.sort((a, b) => a.totalWithConditionalDiscounts - b.totalWithConditionalDiscounts)
      : tariffsWithBreakdown.sort((a, b) => a.total - b.total);
  }
  return tariffsWithBreakdown;
};

export default useTariffsWithPricingBreakdown;
