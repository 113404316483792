import { powersensorApi } from 'services/powersensor/api';
import { HouseholdRequest, PowerSensorAccountData } from 'services/powersensor/types';

const ENDPOINT = '/household-shared';

export const getHouseholdData = async ({
  authToken,
  startTime,
  endTime,
  emails,
  interval = '30min',
}: HouseholdRequest): Promise<PowerSensorAccountData> => {
  const body = {
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
    interval,
    emails,
  };
  try {
    const { data } = await powersensorApi.post(ENDPOINT, { body, authToken });
    const result = data.items[0];
    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting powersensor data', error);
    throw error;
  }
};
