import styled from '@emotion/styled';
import { Box, Theme, Typography } from '@mui/material';
import { ReactComponent as FileUploadIcon } from 'assets/svg/fileUploadIcon.svg';
import csv from 'csvtojson';
import { DropzoneOptions, DropzoneState, useDropzone } from 'react-dropzone';
import { useStore } from 'store';
import { csvRowsToUsageData, isValidIntervalData } from 'utils/dataReader';

const getColor = ({ isDragAccept, isDragReject, theme }: Partial<DropzoneState> & { theme: Theme }) => {
  if (isDragAccept) return theme.palette.success.main;
  if (isDragReject) return theme.palette.error.main;
  return theme.palette.primary.main;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  margin: 3rem auto;
`;

const DropzoneContainer = styled(Box)<Partial<DropzoneState>>`
  align-items: center;
  max-width: 24rem;
  height: 16rem;
  border-radius: 10px;
  border: 2px dashed ${props => getColor(props)};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  outline: none;
  padding: 1.5rem;
  transition: border 0.24s ease-in-out;
  box-shadow: ${({ theme }) => theme.shadows[4]};
`;

const RejectTypography = styled(Typography)`
  cursor: not-allowed;
  user-select: none;
  color: ${({ theme }) => theme.palette.error.dark};
  text-align: center;
`;

const RequestTypography = styled(Typography)`
  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.palette.text.disabled};
  text-align: center;
`;

const FilledCircle = styled(Box)`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const DashedCircle = styled(Box)`
  width: 11rem;
  height: 11rem;
  border-radius: 50%;
  border: 2px dashed ${({ theme }) => theme.palette.grey[100]};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledFileUploadIcon = styled(FileUploadIcon)<Partial<DropzoneState>>`
  width: 2rem;
  height: 2rem;
  color: ${props => getColor(props)};
`;

const defaultOptions: Partial<DropzoneOptions> = {
  maxFiles: 1,
  accept: {
    'text/csv': ['.csv'],
    'application/vnd.ms-excel': ['.csv'],
  }, // NOTE(pdiego): https://github.com/react-dropzone/react-dropzone/issues/276
};

export const FileUploadDropzone = () => {
  const { setUsage } = useStore();

  const onDrop = (acceptedFiles: File[]) => {
    const reader = new FileReader();
    const file = acceptedFiles[0];
    // eslint-disable-next-line no-console
    reader.onabort = () => console.warn('file reading was aborted');
    // eslint-disable-next-line no-console
    reader.onerror = () => console.error('file reading failed');
    reader.onload = () => {
      if (!reader.result) return;
      csv({
        noheader: true,
        output: 'json',
      })
        .fromString(reader.result as string)
        .then(csvRows => {
          if (!isValidIntervalData(csvRows)) {
            alert('Incorrect data format, please upload a CSV file containing interval metering data measured in kWh.');
            return;
          }

          const csvUsageData = csvRowsToUsageData(csvRows);
          setUsage(csvUsageData);
        });
    };
    reader.readAsBinaryString(file);
  };
  const { getInputProps, getRootProps, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
    ...defaultOptions,
    onDrop,
  });

  const file: File | undefined = acceptedFiles?.[0];

  return (
    <Container>
      <DropzoneContainer {...getRootProps({ isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <DashedCircle display="flex" justifyContent="center" alignItems="center">
          <FilledCircle display="flex" justifyContent="center" alignItems="center">
            <StyledFileUploadIcon {...{ isDragReject, isDragAccept }} />
            <RequestTypography>Upload CSV</RequestTypography>
          </FilledCircle>
        </DashedCircle>
        {isDragReject && <RejectTypography>Please upload CSV file</RejectTypography>}
        {!isDragReject && <RequestTypography>Drag &amp; drop a CSV here, or click to select data file</RequestTypography>}
      </DropzoneContainer>
      {file && (
        <Typography variant="caption" style={{ wordWrap: 'break-word' }}>
          <strong>File:</strong> {file.name}
        </Typography>
      )}
    </Container>
  );
};
