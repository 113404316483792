import { Typography } from '@mui/material';
import { groupTariffRateByDates } from 'utils/tariffHelper';
import { dayIndexesToText, intervalArrayToHourString } from 'utils/timeHelper';
import { TariffRate } from 'utils/types';

type RateDescription = {
  dayText: string;
  timeText: string;
  rateText: string;
};

const formatAmount = (amount: number) => `${amount.toFixed(2).replace(/\.?0+$/, '')}`;

const TariffRatesView: React.FC<{ rates: TariffRate[] }> = ({ rates }) => {
  const groupedRates = groupTariffRateByDates(rates);
  const rateToDescription = (rate: TariffRate): RateDescription => ({
    dayText: dayIndexesToText(rate.period.daysOfTheWeekIndex),
    timeText: intervalArrayToHourString(rate.period.intervalsIndex),
    rateText: `${formatAmount(rate.rate)} ${rate.type}`,
  });
  return (
    <>
      {Object.keys(groupedRates).map((date, dateIndex) => {
        const rates = groupedRates[date];
        const { peakRates, shoulderRates, offPeakRates } = rates.reduce(
          (acc, rate) => {
            const newRateDescription = rateToDescription(rate);
            if (rate.period.type === 'peak') {
              const existingRate = acc.peakRates.find(
                r => r.rateText === newRateDescription.rateText && r.dayText === newRateDescription.dayText,
              );
              if (existingRate) {
                existingRate.timeText += `, ${newRateDescription.timeText}`;
              } else {
                acc.peakRates.push(newRateDescription);
              }
            } else if (rate.period.type === 'shoulder') {
              const existingRate = acc.shoulderRates.find(
                r => r.rateText === newRateDescription.rateText && r.dayText === newRateDescription.dayText,
              );
              if (existingRate) {
                existingRate.timeText += `, ${newRateDescription.timeText}`;
              } else {
                acc.shoulderRates.push(newRateDescription);
              }
            } else {
              const existingRate = acc.offPeakRates.find(
                r => r.rateText === newRateDescription.rateText && r.dayText === newRateDescription.dayText,
              );
              if (existingRate) {
                existingRate.timeText += `, ${newRateDescription.timeText}`;
              } else {
                acc.offPeakRates.push(newRateDescription);
              }
            }
            return acc;
          },
          {
            peakRates: [] as RateDescription[],
            shoulderRates: [] as RateDescription[],
            offPeakRates: [] as RateDescription[],
          },
        );
        return (
          <>
            <Typography key={dateIndex} variant="subtitle1" sx={{ textDecoration: 'underline', mt: 1 }}>
              {date}
            </Typography>
            {peakRates.map((rate, i) => (
              <>
                <Typography key={`peak-${i}-date`} variant="body1">{`${rate.dayText} ${rate.timeText}`}</Typography>
                <Typography key={`peak-${i}-rate`} variant="body1">
                  <b>{rate.rateText}</b> (peak)
                </Typography>
              </>
            ))}
            {offPeakRates.map((rate, i) => (
              <>
                <Typography key={`offpeak-${i}-date`} variant="body1">{`${rate.dayText} ${rate.timeText}`}</Typography>
                <Typography key={`offpeak-${i}-rate`} variant="body1">
                  <b>{rate.rateText}</b> (off-peak)
                </Typography>
              </>
            ))}
            {shoulderRates.map((rate, i) => (
              <>
                <Typography key={`shoulder-${i}-date`} variant="body1">{`${rate.dayText} ${rate.timeText}`}</Typography>
                <Typography key={`shoulder-${i}-rate`} variant="body1">
                  <b>{rate.rateText}</b> (shoulder)
                </Typography>
              </>
            ))}
          </>
        );
      })}
    </>
  );
};

export default TariffRatesView;
