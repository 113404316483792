import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import {
  AERContractTermType,
  AERControlledLoadSingleRate,
  AERControlledLoadTOURate,
  AERDay,
  AERPayerType,
  AERPlanControlledLoadV2,
  AERPlanControlledTariffBlockType,
  AERPlanDetailV3,
  AERPlanDiscount,
  AERPlanDiscountMethod,
  AERPlanDiscountType,
  AERPlanPricingModel,
  AERPlanSolarFeedInTariffV3,
  AERPlanTariffBlockType,
  AERPlanTariffPeriod2,
  AERScheme,
  AERSolarTariffType,
  AERTariffDemandCharge,
  AERTariffRate,
  AERTariffTimeOfUseRate,
  AERTariffTimeOfUseType,
} from 'services/aer/types';
import { ALL_DAY_INDEXES, ALL_INTERVAL_INDEXES, GST_RATE, MONTHS_IN_YEAR, YEAR_DURATION } from 'utils/constants';
import { convertTimeStringToDayjs, getIntervalsFromDates, removeYear } from 'utils/timeHelper';
import {
  ControlledLoadRate,
  Period,
  PeriodType,
  PricingModel,
  Tariff,
  TariffCycle,
  TariffDiscount,
  TariffDiscountMethod,
  TariffDiscountType,
  TariffRate,
  TariffRateType,
  TariffSupply,
} from 'utils/types';

const gstInclusive = (rate: number) => new Decimal(rate).plus(new Decimal(rate).times(GST_RATE)).toDecimalPlaces(4).toNumber();

const getSupplyRate = (tariffPeriod: AERPlanTariffPeriod2[]): TariffSupply => {
  const dailySupplyCharge = tariffPeriod.find(t => t.dailySupplyCharge)?.dailySupplyCharge;
  return {
    rate: dailySupplyCharge ? gstInclusive(Number(dailySupplyCharge) * 100) : 0,
    type: '¢/day',
  };
};

const getTariffRate = ({
  startDate = '01-01',
  endDate = '12-31',
  unitPrice,
  type = 'peak',
  days = ALL_DAY_INDEXES,
  intervals = ALL_INTERVAL_INDEXES,
  threshold,
  tariffRateType = '¢/kWh',
  duration,
}: {
  startDate?: string;
  endDate?: string;
  unitPrice: Number;
  type?: PeriodType;
  days?: number[];
  intervals?: number[];
  threshold?: number;
  tariffRateType?: TariffRateType;
  duration?: string; // ISO 8601 Durations
}): TariffRate => {
  const [sanitizedStartDate, sanitizedEndDate] = removeYear(startDate, endDate);
  return {
    startDate: sanitizedStartDate,
    endDate: sanitizedEndDate,
    rate: gstInclusive(Number(unitPrice)),
    type: tariffRateType,
    period: {
      type,
      daysOfTheWeekIndex: days,
      intervalsIndex: intervals,
    },
    threshold,
    duration,
  };
};

const AERTariffTimeOfUseTypeToPeriodType = (type: AERTariffTimeOfUseType): PeriodType => {
  switch (type) {
    case AERTariffTimeOfUseType.OFF_PEAK:
      return 'off_peak';
    case AERTariffTimeOfUseType.PEAK:
      return 'peak';
    default:
      return 'shoulder';
  }
};

const AERDayToDayOfTheWeekIndex = (day: AERDay): number[] => {
  switch (day) {
    case AERDay.SUNDAY:
    case AERDay.SUNDAY_FULL:
      return [0];
    case AERDay.MONDAY:
    case AERDay.MONDAY_FULL:
      return [1];
    case AERDay.TUESDAY:
    case AERDay.TUESDAY_FULL:
      return [2];
    case AERDay.WEDNESDAY:
    case AERDay.WEDNESDAY_FULL:
      return [3];
    case AERDay.THURSDAY:
    case AERDay.THURSDAY_FULL:
      return [4];
    case AERDay.FRIDAY:
    case AERDay.FRIDAY_FULL:
      return [5];
    case AERDay.SATURDAY:
    case AERDay.SATURDAY_FULL:
      return [6];
    case AERDay.BUSINESS_DAYS:
      return [1, 2, 3, 4, 5];
    case AERDay.PUBLIC_HOLIDAYS:
      // NOTE (pdiego): Our model does not support this currently
      // eslint-disable-next-line no-console
      console.warn('PUBLIC_HOLIDAYS not supported');
      return [-1];
  }
};
const AERDaysToDayIndexes = (days?: AERDay[]): number[] =>
  days?.flatMap(AERDayToDayOfTheWeekIndex).filter(d => d !== -1) ?? ALL_DAY_INDEXES;

const demandChargeToTariffRateWithDates =
  (startDate?: string, endDate?: string) =>
  ({ days, startTime, endTime, amount, chargePeriod }: AERTariffDemandCharge): TariffRate => {
    const dayDemandRate =
      chargePeriod === 'MONTH' ? new Decimal(amount).times(MONTHS_IN_YEAR).dividedBy(YEAR_DURATION).toNumber() : Number(amount);
    return getTariffRate({
      startDate,
      endDate,
      unitPrice: dayDemandRate,
      days: AERDaysToDayIndexes(days),
      intervals: getIntervalsFromDates(convertTimeStringToDayjs(startTime), convertTimeStringToDayjs(endTime)),
      type: 'peak',
    });
  };

const solarTariffToTariffRate = (solarFeedInTariff: AERPlanSolarFeedInTariffV3): TariffRate[] => {
  const [startDate, endDate] = removeYear(solarFeedInTariff.startDate, solarFeedInTariff.endDate);
  switch (solarFeedInTariff.tariffUType) {
    case AERSolarTariffType.SINGLE_TARIFF:
      const periodType: PeriodType = 'peak';
      const period = {
        type: periodType,
        daysOfTheWeekIndex: ALL_DAY_INDEXES,
        intervalsIndex: ALL_INTERVAL_INDEXES,
      };
      const singleRates = solarFeedInTariff.singleTariff?.rates ?? [];
      return singleRates.map(rate => ({
        startDate,
        endDate,
        rate: Number(rate.unitPrice) * 100, // NOTE (pdiego): No need to include GST
        type: '¢/kWh',
        period,
        threshold: rate.volume,
      }));
    case AERSolarTariffType.TIME_VARYING_TARIFF:
      const varyingTariffs = solarFeedInTariff.timeVaryingTariffs ?? [];
      return varyingTariffs
        .map(({ rates = [], timeVariations = [], type = AERTariffTimeOfUseType.PEAK }) => {
          if (rates.length > 1) {
            // NOTE (pdiego): We have not seen multiple rates here yet. If they exist, we need to find out how to handle them.
            // Is the order of the timeVariations and rates arrays related?
            // eslint-disable-next-line no-console
            console.error('NOT IMPLEMENTED', solarFeedInTariff);
          }
          const timeVaryingRate = rates[0];
          const periodsFromTimeVariations: Period[] = timeVariations.map(({ days, startTime = '0000', endTime = '2359' }) => ({
            type: AERTariffTimeOfUseTypeToPeriodType(type),
            daysOfTheWeekIndex: AERDaysToDayIndexes(days),
            intervalsIndex: getIntervalsFromDates(convertTimeStringToDayjs(startTime), convertTimeStringToDayjs(endTime)),
          }));
          const result: TariffRate[] = periodsFromTimeVariations.map(period => ({
            startDate,
            endDate,
            rate: Number(timeVaryingRate.unitPrice) * 100, // NOTE (pdiego): No need to include GST
            type: '¢/kWh',
            period,
            threshold: timeVaryingRate.volume,
          }));
          return result;
        })
        .flat();
  }
};

const flatRateToTariffRateWithDates =
  (startDate?: string, endDate?: string, duration?: string) =>
  ({ unitPrice, volume: threshold }: AERTariffRate): TariffRate =>
    getTariffRate({
      startDate,
      endDate,
      unitPrice: Number(unitPrice) * 100,
      threshold,
      duration,
    });

const timeOfUseRateToTariffRatesWithDates =
  (startDate?: string, endDate?: string, duration?: string) =>
  (r: AERTariffTimeOfUseRate): TariffRate[] =>
    r.timeOfUse.flatMap(({ days, startTime, endTime }) =>
      r.rates.flatMap(rate =>
        getTariffRate({
          startDate,
          endDate,
          unitPrice: Number(rate.unitPrice) * 100,
          days: AERDaysToDayIndexes(days),
          intervals: getIntervalsFromDates(convertTimeStringToDayjs(startTime), convertTimeStringToDayjs(endTime)),
          type: AERTariffTimeOfUseTypeToPeriodType(r.type),
          threshold: rate.volume,
          duration,
        }),
      ),
    );

const tariffPeriodToTariffRate = (tariffPeriod: AERPlanTariffPeriod2): TariffRate[] => {
  const { startDate, endDate, singleRate, timeOfUseRates = [], demandCharges = [], rateBlockUType } = tariffPeriod;
  switch (rateBlockUType) {
    case AERPlanTariffBlockType.SINGLE_RATE:
      const singleRates = singleRate?.rates ?? [];
      const singlePeriod = singleRate?.period ?? 'P1Y';
      return singleRates.map(flatRateToTariffRateWithDates(startDate, endDate, singlePeriod));
    case AERPlanTariffBlockType.TIME_OF_USE:
      const touPeriod = timeOfUseRates.find(t => t.period!!)?.period ?? 'P1Y';
      return timeOfUseRates.flatMap(timeOfUseRateToTariffRatesWithDates(startDate, endDate, touPeriod));
    case AERPlanTariffBlockType.DEMAND:
      return demandCharges.map(demandChargeToTariffRateWithDates(startDate, endDate));
  }
};

// NOTE (pdiego): We've only seen rates as an array with a single item.
const controlledLoadToControlledLoadRate = ({
  singleRate,
  timeOfUseRates = [],
  rateBlockUType,
}: AERPlanControlledLoadV2): ControlledLoadRate[] => {
  switch (rateBlockUType) {
    case AERPlanControlledTariffBlockType.SINGLE_RATE:
      if (!singleRate) {
        return [];
      }
      return [singleRateCLToControlledLoadRate(singleRate)];
    case AERPlanControlledTariffBlockType.TIME_OF_USE:
      return timeOfUseRates.flatMap(timeOfUseRateCLToControlledLoadRate);
  }
};

const singleRateCLToControlledLoadRate = ({ dailySupplyCharge, rates }: AERControlledLoadSingleRate): ControlledLoadRate => ({
  supply: {
    rate: gstInclusive(Number(dailySupplyCharge) * 100),
    type: '¢/day',
  },
  rate: getTariffRate({
    unitPrice: Number(rates[0].unitPrice) * 100,
  }),
});

const timeOfUseRateCLToControlledLoadRate = ({
  dailySupplyCharge,
  rates,
  timeOfUse,
}: AERControlledLoadTOURate): ControlledLoadRate[] =>
  timeOfUse.flatMap(({ days, startTime, endTime }) =>
    rates.map(rate => ({
      supply: {
        rate: gstInclusive(Number(dailySupplyCharge) * 100),
        type: '¢/day',
      },
      rate: getTariffRate({
        unitPrice: Number(rate.unitPrice) * 100,
        days: AERDaysToDayIndexes(days),
        intervals: getIntervalsFromDates(convertTimeStringToDayjs(startTime), convertTimeStringToDayjs(endTime)),
      }),
    })),
  );

const SUPPORTED_DISCOUNT_METHODS = [AERPlanDiscountMethod.PERCENT_OF_BILL, AERPlanDiscountMethod.PERCENT_OF_USE];

const isSupportedDiscount = ({ methodUType }: AERPlanDiscount): boolean => SUPPORTED_DISCOUNT_METHODS.includes(methodUType);

const aerToTariffDiscountType: Record<AERPlanDiscountType, TariffDiscountType> = {
  [AERPlanDiscountType.GUARANTEED]: 'guaranteed',
  [AERPlanDiscountType.CONDITIONAL]: 'conditional',
  [AERPlanDiscountType.OTHER]: 'other',
};

const aerDiscountToTariffDiscount = (discount: AERPlanDiscount): TariffDiscount => {
  const percentage = Number(discount.percentOfUse?.rate ?? discount.percentOfBill?.rate ?? 0);
  const method =
    discount.methodUType === AERPlanDiscountMethod.PERCENT_OF_BILL ? TariffDiscountMethod.BILL : TariffDiscountMethod.CONSUMPTION;
  const type = aerToTariffDiscountType[discount.type];
  return { percentage, method, type, description: discount.description };
};

const iso8601DurationToTariffCycle = (duration = 'P1Y'): TariffCycle => {
  switch (duration) {
    case 'P1Y':
      return 'yearly';
    case 'P3M':
      return 'quarterly';
    case 'P2M':
      return 'bi-monthly';
    case 'P1M':
      return 'monthly';
    case 'P1W':
    case 'P7D':
      return 'weekly';
    case 'P1D':
      return 'daily';
    default:
      // eslint-disable-next-line no-console
      console.warn('ISO 8601 Duration not mapped', duration);
      return 'yearly';
  }
};

const mapTermType = (termType?: AERContractTermType) => {
  if (!termType || termType === AERContractTermType.OTHER) return 'Unknown';
  switch (termType) {
    case AERContractTermType.A_YEAR:
      return '1 year contract';
    case AERContractTermType.TWO_YEARS:
      return '2 years contract';
    case AERContractTermType.THREE_YEARS:
      return '3 years contract';
    case AERContractTermType.FOUR_YEARS:
      return '4 years contract';
    case AERContractTermType.FIVE_YEARS:
      return '5 years contract';
    case AERContractTermType.ONGOING:
      return 'Ongoing';
  }
};

const mapPricingModel = (pricingModel: AERPlanPricingModel): PricingModel => {
  switch (pricingModel) {
    case AERPlanPricingModel.SINGLE_RATE:
    case AERPlanPricingModel.SINGLE_RATE_CONT_LOAD:
      return 'flat';
    case AERPlanPricingModel.TIME_OF_USE:
    case AERPlanPricingModel.TIME_OF_USE_CONT_LOAD:
      return 'time-of-use';
    case AERPlanPricingModel.QUOTA:
    case AERPlanPricingModel.FLEXIBLE:
    case AERPlanPricingModel.FLEXIBLE_CONT_LOAD:
      return 'flexible';
  }
};

export const AERTariffToTariff = (aerPlanDetail: AERPlanDetailV3): Tariff => {
  const { electricityContract, displayName: name, brandName: retailer } = aerPlanDetail;
  const {
    solarFeedInTariff = [],
    tariffPeriod,
    discounts = [],
    controlledLoad = [],
    eligibility = [],
    fees = [],
    incentives,
  } = electricityContract;

  const retailerSolarRates = solarFeedInTariff.filter(s => s.payerType === AERPayerType.RETAILER);

  // NOTE (pdiego): Sometimes demandCharges is an array present on the initial tariffPeriod
  const demandCharges = tariffPeriod[0].demandCharges ?? [];
  const datePeriods = tariffPeriod.map(t => ({
    startDate: t.startDate,
    endDate: t.endDate,
  }));
  const convolutedDemandCharges = demandCharges.length
    ? demandCharges.map((d, i) => demandChargeToTariffRateWithDates(datePeriods[i].startDate, datePeriods[i].endDate)(d))
    : [];

  const { demand, consumption: consumptionRates } = tariffPeriod.reduce(
    (acc, t) => {
      if (t.rateBlockUType === AERPlanTariffBlockType.DEMAND) {
        return {
          ...acc,
          demand: [...acc.demand, ...tariffPeriodToTariffRate(t)],
        };
      }
      return {
        ...acc,
        consumption: [...acc.consumption, ...tariffPeriodToTariffRate(t)],
      };
    },
    {
      demand: [] as TariffRate[],
      consumption: [] as TariffRate[],
    },
  );

  const demandRates = [...demand, ...convolutedDemandCharges];
  const generationRates = retailerSolarRates.filter(r => r.scheme !== AERScheme.PREMIUM).flatMap(solarTariffToTariffRate);
  const consumptionCycleDuration = consumptionRates.find(t => t.duration!!)?.duration;
  const generationCycleDuration = generationRates.find(t => t.duration!!)?.duration;

  return {
    id: aerPlanDetail.planId.split('@')[0],
    startDate: dayjs(),
    name,
    retailer,
    eligibility: eligibility.map(e => e.information),
    supply: getSupplyRate(tariffPeriod),
    consumptionRates,
    controlledLoadRates: controlledLoad.flatMap(controlledLoadToControlledLoadRate),
    // NOTE (pdiego): Premium schemes are no longer available
    generationRates,
    demandRates,
    cycle: {
      generation: iso8601DurationToTariffCycle(generationCycleDuration),
      consumption: iso8601DurationToTariffCycle(consumptionCycleDuration),
    },
    discounts: discounts.filter(isSupportedDiscount).map(aerDiscountToTariffDiscount),
    fees,
    incentives,
    termType: mapTermType(electricityContract.termType),
    pricingModel: mapPricingModel(electricityContract.pricingModel),
  };
};
